import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  bar_open() {
    console.log('open');
    const isSidebar = document.getElementById("isSidebar");
    if (isSidebar.style.display === 'block') {
      isSidebar.style.display = 'none';
    } else {
      isSidebar.style.display = 'block';
    }
  }
  
  bar_close() {
    console.log('close');    
    const isSidebar = document.getElementById("isSidebar");
    isSidebar.style.display = "none";
  }

}
