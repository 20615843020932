<!-- Header with full-height image -->
<header class="bgimg-1 is-display-container" id="home">
  <h1 style="padding-top: 200px;text-align: center;color: orangered;font-weight: bolder; font-size: 3em;">WEBSITE UNDER
    CONSTRUCTION!</h1>
    <div class="green-border">
      <div class="contact-info">
        <span>Contact: Richard</span>
        <span>Mobile: 0457 093 647</span>
        <span>Email:<a href="richard@altusautomation.com.au" target="_top"> richard@altusautomation.com.au</a></span>
        
      </div>
    </div>

  <!-- <div class="is-display-left home-content">
      <div class="">
        <span class="is-jumbo is-hide-small logo-black">ALTUS AUTOMATION</span>
        <span class="is-hide-small"><img class="altus-logo" src="../../../assets/images/Group 8.png" alt=""></span>
        <span class="is-xxlarge is-hide-large is-hide-medium logo-black heading_font">ALTUS AUTOMATION</span>
      </div>
      <br>
      <br>
      <span style="padding: 0 40px 0;" class="is-xlarge bubble">Pellentesque elementum libero non enim iaculis, vitae vestibulum arcu molestie.</span><br><br>
      <span style="padding: 0 70px 0;" class="is-large bubble">Duis aliquam cursus nisl. Sed eget est et orci dapibus dictum vitae non nulla. Pellentesque est duit.</span>
    </div>  -->
</header>