<!-- Promo Section "Statistics" -->

<!-- About Section -->
<div class="is-container" style="padding:25px 15px" id="about">
    <h3 class="is-center is-padding-top-24">ABOUT ALTUS AUTOMATION</h3>
    <div class="is-center" style="margin-top:64px">
      <div class="is-third">
        <div class="company-img">
          <img src="../../../assets/images/company.jpg" alt="">
        </div>
        <fa-icon class="is-margin-bottom is-jumbo" [icon]="['fas', 'globe']"></fa-icon>
        <p class="is-large">The Company</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.
        </p>
      </div>
      <div class="is-third">
        <div class="company-img">
          <img src="../../../assets/images/vision.jpg" alt="">
        </div>
        <fa-icon class="is-margin-bottom is-jumbo" [icon]="['fas', 'question']"></fa-icon>
        <p class="is-large">Why Altus</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.
        </p>
      </div>
      <div class="is-third">
        <div class="company-img">
          <img src="../../../assets/images/sustainability.jpg" alt="">
        </div>
        <fa-icon class="is-margin-bottom is-jumbo" [icon]="['fas', 'screwdriver']"></fa-icon>
        <p class="is-large">Services</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.
        </p>
      </div>
    </div>
  </div>
  
  <!-- Promo Section - "We know design" -->
  <!-- <div class="is-container is-light-grey" style="padding:128px 16px">
    <div class="is-row-padding">
      <div class="is-col m6">
        <h3>Our current Inventry of Items</h3>
        <p>We have provided some detailed information about our current inventry of items.</p>
        <p><a routerLink="/products" class="is-button is-black">
            <fa-icon class="is-margin-right" [icon]="['fas', 'object-group']"></fa-icon>View Our Inventry
          </a></p>
      </div>
      <div class="is-col m6">
        <img class="is-image is-round-large" src="../assets/images/multi-wood.jpg" alt="Buildings" width="700"
          height="394">
      </div>
    </div>
  </div> -->