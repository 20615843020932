  <!-- Footer -->
  <footer class="is-center is-card footer">
    <!-- <a routerLink="/home" class="is-button is-light-grey"><fa-icon class="is-margin-right" [icon]="['fas', 'home']"></fa-icon>Home</a> -->
    <div class="is-xlarge">
      <fa-icon class="is-hover-blue-color is-padding-small" [icon]="['fab','facebook']"></fa-icon>
      <fa-icon class="is-hover-red-color is-padding-small" [icon]="['fab','instagram']"></fa-icon>
      <fa-icon class="is-hover-yellow-color is-padding-small" [icon]="['fab','snapchat']"></fa-icon>
      <fa-icon class="is-hover-blue-color is-padding-small" [icon]="['fab','twitter']"></fa-icon>
      <fa-icon class="is-hover-blue-color is-padding-small" [icon]="['fab','linkedin']"></fa-icon>
    </div>
    <p>Powered by <a href="https://www.jedimedia.it" class="is-hover-text-green">JEDI Media</a></p>
  </footer>