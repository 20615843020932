<!-- Navbar (sit on top) -->
<div class="is-top ">
    <div class="is-bar is-card">
      <a routerLink="/home" class="is-bar-item is-button is-wide">
        <span class="is-hide-small">HOME</span>
        <fa-icon class="is-hide-large is-hide-medium" [icon]="['fas', 'home']"></fa-icon>
      </a>
      <!-- Right-sided navbar links -->
      <div class="is-right is-hide-small">
        <a routerLink="" class="is-bar-item is-button">ABOUT</a>
        <a routerLink="" class="is-bar-item is-button"><i class="fa fa-envelope"></i> CONTACT</a>
      </div>
      <!-- Hide right-floated links on small screens and replace them with a menu icon -->
  
      <a class="is-bar-item is-button is-right is-hide-large is-hide-medium" (click)="bar_open()">
        <fa-icon icon="bars"></fa-icon>
      </a>
    </div>
  </div>
  
  <!-- Sidebar on small screens when clicking the menu icon -->
  <nav class="is-sidebar is-bar-block is-card is-animate-left is-hide-medium is-hide-large" style="display:none"
    id="isSidebar">
    <a (click)="bar_close()" class="is-bar-item is-button is-large is-padding-16">Close</a>
    <a routerLink="/about" (click)="bar_close()" class="is-bar-item is-button">ABOUT</a>
    <a routerLink="/contact" (click)="bar_close()" class="is-bar-item is-button">CONTACT</a>
  </nav>