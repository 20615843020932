import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './features/navbar/navbar.component';
import { FooterComponent } from './features/footer/footer.component';
import { HomeComponent } from './pages/home/home.component';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faBars, faHome, faEnvelope, faWarehouse, faHandsHelping, faCommentDots, faTree, faObjectGroup, faMapMarker, faPhone, faPaperPlane, faGlobe, faLandmark, faGavel, faScrewdriver, faQuestion } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram, faSnapchat, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { AboutComponent } from './pages/about/about.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    HomeComponent,
    AboutComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary){
    library.addIcons(faBars, faHome, faFacebook, faInstagram, faSnapchat, faTwitter, faLinkedin, faEnvelope, faWarehouse,
      faHandsHelping, faCommentDots, faTree, faObjectGroup, faMapMarker, faPhone, faPaperPlane, faGlobe, faLandmark, faGavel,
      faScrewdriver, faQuestion)
  }
 }
